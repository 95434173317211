import React, {createContext, ReactElement, useContext, useMemo} from "react";

import { useNavigate } from "react-router-dom";
import axios, {AxiosError} from "axios";
import AuthResponse from "../models/AuthResponse";
import {Path, PathMap} from "../PathProvider";
import {GlobalState, useStateMachine} from "little-state-machine";

const AuthContext = createContext<AuthContextTypes>({
    user: null,
    login : () => null,
    logout : () => null,
    setNewAccessToken: () => null,
    updateLastAttestationDate : () => null
});

interface AuthProviderProps {
    children : ReactElement
}

export type AuthContextTypes = {
    user: AuthResponse | null;
    login : (data: AuthResponse) => void;
    logout : (data?: string) => void;
    setNewAccessToken : (data : string) => void;
    updateLastAttestationDate : () => void;
};

function updateUser(state : GlobalState, user : AuthResponse) {
    return {
        ...state,
        user : user
    };
}

function clearUser(state : GlobalState) {
    return {
        ...state,
        user : null
    };
}

export function AuthProvider(props : AuthProviderProps) {
    const {actions, state : {user}} = useStateMachine({clearUser, updateUser});
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = (data : AuthResponse) => {
        actions.updateUser(data);
        // we use PathMap here instead of usePath because the PathProvider is inside the AuthProvider, thus we don't
        // have access to the usePath hook in here
        if (data.superAdmin) {
            navigate(PathMap.get(Path.ThirdPartyTable)?.path ?? '');
        } else if (data.admin) {
            navigate(PathMap.get(Path.UserTable)?.adminPath ?? '');
        } else {
            navigate(PathMap.get(Path.SUMR)?.path ?? '');
        }
    };

    // call this function to sign out logged in user
    const logout = (message? : string) => {
		axios.delete(
			`${process.env.REACT_APP_SERVER_URL ?? window.location.origin}/api/deleteSession`,
            { headers :{ 'Authorization' : `Bearer ${user?.accessToken}`} }
		).catch((err : AxiosError) => {
			console.error(err);
		})
		
        actions.clearUser();

        // we use PathMap here instead of usePath because the PathProvider is inside the AuthProvider, thus we don't
        // have access to the usePath hook in here
        navigate(PathMap.get(Path.Login)?.path ?? '', { replace: true, state : {warningMessage : message ?? 'You have been successfully logged out'} });
    };

    const setNewAccessToken = (accessToken : string) => {
        if (user) {
            actions.updateUser({...user, accessToken : accessToken});
        } else {
            logout();
        }
    };

    const updateLastAttestationDate = () => {
        if (user) {
            actions.updateUser({...user, thirdParty : {...user?.thirdParty, lastAttestationDate: new Date()} });
        } else {
            logout();
        }
    };

    const value = useMemo<AuthContextTypes>(
        () => ({
            user,
            login,
            logout,
            setNewAccessToken,
            updateLastAttestationDate
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};