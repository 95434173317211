import styled, {css} from 'styled-components';
import DatePicker from "react-date-picker";

const inputAndSelectStyles = css`
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 5px;
  min-width: 200px;
`
const StyledInput = styled.input`
  ${inputAndSelectStyles}
`

const StyledDatePicker = styled(DatePicker)`
  ${inputAndSelectStyles}
  .react-date-picker__wrapper {
    border: none;
  }

  width: 100px;
`

const StyledSelect = styled.select`
  ${inputAndSelectStyles}
`

const StyledTextArea = styled.textarea`
  ${inputAndSelectStyles};
  height: 15rem
`

const StyledCheckboxOrRadioInput = styled.input`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
`

const Label = styled.label<{ checkbox?: boolean, largeFont?: boolean }>`
  display: ${props => props.checkbox ? 'flex' : 'block'};
  ${props => props.checkbox
          ? css`
            align-items: center;
            margin-bottom: 1rem !important;
          `
          : ''
  };
  margin: 0;
  font-size: ${props => props.largeFont ? '1.125rem' : 'inherit'};
  font-weight: 400;
  line-height: 1.8;
  color: #0a0a0a;
`

const primaryButtonStyles = css<{ float?: 'right' | 'left' }>`
  background-color: #0060a9;
  border: 1px solid #0060a9;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  max-height: 50px;
  text-transform: uppercase;
  text-decoration: none;
  float: ${props => props.float ?? 'right'};
  align-self: ${props => props.float === 'left' ? 'start' : 'end'};
  text-align: center;

  &:hover:enabled {
    color: #fff !important;
    background-color: #004376 !important;
  }

  &:disabled {
    opacity: .5;
    cursor: default;
  }
`

const PrimaryButton = styled.button<{ float?: 'right' | 'left' }>`
  ${primaryButtonStyles};
  min-width: 200px;
  padding: 0.8rem;
`

const SmallPrimaryButton = styled.button<{ float?: 'right' | 'left' }>`
  ${primaryButtonStyles};
  max-width: 250px;
  padding: 0.6rem 0.8rem;
`

const SecondaryButton = styled.button<{ float?: 'right' | 'left' }>`
  background-color: white;
  border: 1px solid #0060a9;
  color: #0060a9;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  max-height: 50px;
  text-transform: uppercase;
  text-decoration: none;
  float: ${props => props.float ?? 'left'};
  align-self: ${props => props.float === 'left' ? 'start' : 'end'};
  text-align: center;

  &:hover:enabled {
    color: #0060a9;
    background-color: #d2d2d2 !important;
  }

  &:disabled {
    opacity: .5;
    cursor: default;
  }

  min-width: 200px;
  padding: 0.8rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export {
    StyledInput,
    StyledDatePicker,
    Label,
    PrimaryButton,
    StyledCheckboxOrRadioInput,
    StyledSelect,
    StyledTextArea,
    Form,
    SmallPrimaryButton,
    SecondaryButton
}