import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useAxios} from "../../AxiosProvider";
import axios, {AxiosError, AxiosResponse} from "axios";
import {useMessageService} from "../../hooks/useMessageService";
import {Form, Label, PrimaryButton} from "../../styledcomponents/FormStyledComponents";
import {Divider, PageTitle} from "../../styledcomponents/MiscStyledComponents";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useAuth} from "../../auth/AuthProvider";
import AuthResponse from "../../models/AuthResponse";
import {CheckboxInput} from "../form/FormElements";
import ErrorUtils from "../../utils/errorUtils";

type AttestFormData = {
    acknowledged : boolean
}

export function PostLoginAttestPage() {
    const { state } = useLocation();
    const authResponse : AuthResponse = state.authResponse;

    return(
        <React.Fragment>
            <PageTitle>Welcome {authResponse.firstName}</PageTitle>
            <div>Last login date: {authResponse.lastLoginDate}</div>
            <div>Last attest date: {authResponse.lastAttestDate ? new Date(authResponse.lastAttestDate).toLocaleDateString() : 'N/A'}</div>
            <br />
            <div>
                I, the user, acknowledge that as a licensed Third Party in Pennsylvania (or as a licensed agent of a Third Party)
                that I agree to keep all Company and customer information supplied by the Company pursuant to the Company’s Tariff confidential.
                Such information shall include, but shall not be limited to, customer names, account numbers,
                load research- curves and other load information and passwords granting access to secured information.
                User further agrees to not divulge any customer information to other parties without the customer’s authorization.
            </div>
            <br />
            <div>
                Choosing to proceed any further denotes acceptance of the above.
                Any violation of the above mentioned will be reported to the Public Utilities Commission.
            </div>
            <br/>
            <div style={{fontWeight: 'bold'}}>
                Please certify all users have the required access.
            </div>
            <br />
            <AttestForm />
        </React.Fragment>
    )
}

export function ManualAttestPage() {
    const auth = useAuth();

    return(
        <React.Fragment>
            <div>Last attest date: {auth.user?.thirdParty.lastAttestationDate ? new Date(auth.user.thirdParty.lastAttestationDate).toLocaleDateString() : 'N/A'}</div><br />
            <AttestForm />
        </React.Fragment>
    )
}

function AttestForm() {
    const { state } = useLocation();
    const auth = useAuth();
    const preconfiguredAxios = useAxios();
    const messageService = useMessageService();
    const authResponse : AuthResponse = state?.authResponse || {};

    // useForm hooks
    const methods = useForm<AttestFormData>({
        defaultValues: {
            acknowledged: false
        }
    });
    const { handleSubmit, formState: { isSubmitting, isDirty } } = methods;

    const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(true);

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isDirty);
    }, [isSubmitting, isDirty]);

    // Functions
    const onSubmit: SubmitHandler<AttestFormData> = async () => {
        // clear any messages once we submit
        messageService.clearAll();

        // An admin can access this form manually while logged in, or they can be prompted to attest when logging in
        // if their third party's last attestation date is too old. Whenever the user is logged in we should just use the
        // axios instance that we have configured to make calls
        let axiosCall;
        if (auth?.user) {
            axiosCall = preconfiguredAxios?.secureApi.put(
                `api/admin/updateLastAttestationDate/${auth.user.thirdPartyId}`
            ).then((response: AxiosResponse) => {
                messageService.success('Successfully attested');
                auth.updateLastAttestationDate();
            })
        } else {
            // We don't use the preconfiguredAxios because we need to pass an access token to call this endpoint, and
            // the preconfiguredAxios.secureApi will get the accessToken from the logged in user; however, at this point the user
            // is not logged in (intentionally so the user cannot bypass the attest page)
            // so we need to use a different axios instance to call this endpoint
            axiosCall =  preconfiguredAxios?.secureApi.put(
                `/api/admin/updateLastAttestationDate/${authResponse.thirdPartyId}`,
                {},
                { headers :{ 'Authorization' : `Bearer ${authResponse?.accessToken}`} }
            ).then((response: AxiosResponse) => {
                auth.login(authResponse);
            })
        }

        await axiosCall?.catch((err: AxiosError) => {
            console.error(err);

            messageService.error(ErrorUtils.UNKNOWN_ERROR)
        });
    };

    return(
        <FormProvider {...methods} >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Label checkbox={true} largeFont={true}>
                    <CheckboxInput name="acknowledged"/>
                    I attest that all users have proper access
                </Label>

                <Divider />

                {/* When the user is accessing this form while logged in (from the manual attest page), then we
                    want the button to say 'Submit'. Otherwise, when they're being prompted to attest on login
                    (because last attestation date is too old), we want the button to say 'Continue'
                 */}
                <PrimaryButton type={"submit"} disabled={submitDisabled}>
                    {isSubmitting ? (auth?.user ? 'Submitting...' : 'Continuing...') : (auth?.user ? 'Submit' : 'Continue')}
                </PrimaryButton>
            </Form>
        </FormProvider>
    )
}