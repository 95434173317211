import React, {createContext, ReactElement, useContext, useMemo} from "react";

import {useAuth} from "./auth/AuthProvider";

export const enum Path {
    Base,
    Login,
    ResetPassword,
    AttestPage,
    AcknowledgementPage,
    ThirdPartyTable,
    EditThirdParty,
    DeleteThirdParty,
    AddThirdParty,
    EditUser,
    UserTable,
    AddUser,
    DeleteUser,
    AuditLog,
    ErrorFallback,
    SUMR,
    SUMRSubmit
}


type PathValue = {
    title : string
} & ({
    adminPath : string,
    superAdminPath : string,
    path ?: never
} | {
    path: string,
    adminPath ?: never,
    superAdminPath ?: never
})

export const PathMap : Map<Path, PathValue> = new Map();

function loadPathMap() {
    // paths for anyone
    PathMap.set(Path.Login,               {path : '/login',         title : 'Login'});
    PathMap.set(Path.ResetPassword,       {path : '/resetPassword', title : `Reset Password`});
    PathMap.set(Path.AcknowledgementPage, {path : '/acknowledge',   title : `Acknowledgement`});
    PathMap.set(Path.AuditLog,            {path : '/activityLog',   title : `Activity Log`});
    PathMap.set(Path.ErrorFallback,       {path : 'error',          title : `Error`});
    PathMap.set(Path.SUMR,                {path : '/sumr',          title : `SUMR`});
    PathMap.set(Path.SUMRSubmit,          {path : '/sumr/submit',   title : `SUMR`});

    // Admin only paths
    PathMap.set(Path.AttestPage, {path : '/attest', title : 'Attestation'});

    // Super admin only paths
    PathMap.set(Path.ThirdPartyTable,  {path : '/superAdmin/thirdParty',                            title : 'Third Parties'});
    PathMap.set(Path.EditThirdParty,   {path : `${PathMap.get(Path.ThirdPartyTable)?.path}/edit`,   title : 'Edit Third Party'});
    PathMap.set(Path.DeleteThirdParty, {path : `${PathMap.get(Path.ThirdPartyTable)?.path}/delete`, title : 'Delete Third Party'});
    PathMap.set(Path.AddThirdParty,    {path : `${PathMap.get(Path.ThirdPartyTable)?.path}/add`,    title : 'Add Third Party'});

    // Admin or super admin paths
    PathMap.set(Path.UserTable,  {adminPath : `/admin/users`,                                     superAdminPath : `${PathMap.get(Path.ThirdPartyTable)?.path}/users`,      title : 'Users'});
    PathMap.set(Path.EditUser,   {adminPath : `${PathMap.get(Path.UserTable)?.adminPath}/edit`,   superAdminPath : `${PathMap.get(Path.UserTable)?.superAdminPath}/edit`,   title : 'Edit User'});
    PathMap.set(Path.AddUser,    {adminPath : `${PathMap.get(Path.UserTable)?.adminPath}/add`,    superAdminPath : `${PathMap.get(Path.UserTable)?.superAdminPath}/add`,    title : 'Add User'});
    PathMap.set(Path.DeleteUser, {adminPath : `${PathMap.get(Path.UserTable)?.adminPath}/delete`, superAdminPath : `${PathMap.get(Path.UserTable)?.superAdminPath}/delete`, title : 'Delete User'});
}

const PathContext = createContext<PathContextTypes>({ get : () => '', getTitleFromPathName : () => ''});

interface PathProviderProps {
    children : ReactElement
}

export type PathContextTypes = {
    get : (data : Path) => string;
    getTitleFromPathName : (data : string) => string;
};

export function PathProvider(props : PathProviderProps) {
    const auth = useAuth();

    const get = (path : Path) : string => {
        if (PathMap.size === 0) { loadPathMap(); }

        let pathValue : PathValue | undefined = PathMap.get(path);

        if (!pathValue) { throw new Error(`No paths defined for ${path}`); }

        if (auth.user?.superAdmin && pathValue.superAdminPath) { return pathValue.superAdminPath; }

        if (auth.user?.admin && pathValue.adminPath) { return pathValue.adminPath; }

        return pathValue?.path ?? '';
    };

    const getTitleFromPathName = (pathString : string) : string => {
        let it = PathMap.values();
        let nextValue = it.next();

        while (nextValue) {
            let pathValue = nextValue.value;

            if (!pathValue) { return ''; }

            if (pathValue.path === pathString || pathValue.adminPath === pathString || pathValue.superAdminPath === pathString) {
                return pathValue.title;
            }

            nextValue = it.next();
        }

        return ''
    }

    const value = useMemo<PathContextTypes>(
        () => ({
            get,
            getTitleFromPathName
        }),
        [auth.user]
    );

    return <PathContext.Provider value={value}>{props.children}</PathContext.Provider>;
}

export const usePath = () => {
    return useContext(PathContext);
};