import {
    Message,
    useMessageService
} from "../hooks/useMessageService";
import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {ErrorList, MessageListItem, SuccessList, WarningList} from "../styledcomponents/AlertStyledComponents";

const CloseMessageButton = styled.span`
  cursor: pointer;
  opacity: .6;
  &:hover {
    opacity: 1;
  }
`

const Messages = styled.div`
  grid-area: messages
`

export default function MessageContainer() {
    const messageService = useMessageService();

    const errorMessages   = messageService.messages.filter((message : Message) => { return message.type === 'error' });
    const successMessages = messageService.messages.filter((message : Message) => { return message.type === 'success' });
    const warningMessages = messageService.messages.filter((message : Message) => { return message.type === 'warning' });

    const messageDivRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (messageDivRef.current) {
            messageDivRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }, [errorMessages, successMessages, warningMessages])

    return (
        <Messages ref={messageDivRef}>
            {
                errorMessages.length > 0 &&
                <ErrorList>
                    <div>
                        {errorMessages.map((message : Message) => {
                            return <MessageListItem key={message.id}>{message.text}</MessageListItem>
                        })}
                    </div>
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('error')}>X</CloseMessageButton>
                </ErrorList>
            }
            {
                successMessages.length > 0 &&
                <SuccessList>
                    <div>
                        {successMessages.map((message : Message) => {
                            return <MessageListItem key={message.id}>{message.text}</MessageListItem>
                        })}
                    </div>
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('success')}>X</CloseMessageButton>
                </SuccessList>
            }
            {
                warningMessages.length > 0 &&
                <WarningList>
                    <div>
                        {warningMessages.map((message : Message) => {
                            return <MessageListItem key={message.id}>{message.text}</MessageListItem>
                        })}
                    </div>
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('warning')}>X</CloseMessageButton>
                </WarningList>
            }
        </Messages>
    )
};