import styled from 'styled-components';

const AcknowledgementText = styled.div`
    background-color: #d7ecfa;
    color: #0a0a0a;
    margin: 0 0 1rem 0;
    padding: 1rem;
    border: 1px solid rgba(10,10,10,.25);
    border-radius: 0;
`

const FlexRowContainer = styled.div<{gap ?: number}>`
    display: flex;
    flex-direction: row;
    gap : ${props => props.gap ?? '1rem'}
`

const FlexColumnContainer = styled.div<{gap ?: number}>`
    display: flex;
    flex-direction: column;
    gap : ${props => props.gap ?? '1rem'}
`

const GridColumnContainer = styled.div<{columns : number}>`
    display: grid;
    grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
    gap: 1rem
`

const PageTitle = styled.div` 
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 2rem;
`

const Divider = styled.div`
  border-bottom: 1px solid #D2D2D2;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const NoWrap = styled.div`
  white-space: nowrap;
`

export {
    AcknowledgementText, FlexRowContainer, FlexColumnContainer, PageTitle, Divider, GridColumnContainer, NoWrap
}