import styled from "styled-components";

const MessageList = styled.ul`
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 2rem;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`

const ErrorList = styled(MessageList)`
  background: #fae5e5;
  color: #c00;
  border: 1px solid #c00;
`

const WarningList = styled(MessageList)`
  background: #faf7e5;
  color: #877101;
  border: 1px solid #877101;
`

const SuccessList = styled(MessageList)`
  background: #d9f5d5;
  color: #3d8701;
  border: 1px solid #3d8701;
`

const MessageListItem = styled.li`
  margin-left: 1rem;
  &:only-child {
    list-style-type: none;
    margin-left: 0;
  }
`

export {MessageList, ErrorList, WarningList, SuccessList, MessageListItem}