import React, { useRef, Suspense } from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import styled from "styled-components";
import './App.css';
import { useAuth } from "./auth/AuthProvider";
import MessageContainer from "./components/MessageContainer";
import SideNav from "./components/SideNav";
import firstEnergyLogo from './FE_Logo_White.svg'
import { useMessageService } from "./hooks/useMessageService";
import {FlexRowContainer} from "./styledcomponents/MiscStyledComponents";
import {ManualAttestPage, PostLoginAttestPage} from "./components/admin/AttestPage";
import {Path, usePath} from "./PathProvider";
import {useIdleTimer} from "react-idle-timer";

const ThirdPartyTablePage    = React.lazy(() => import("./components/superAdmin/ThirdPartyTablePage"));
const DeleteThirdParty       = React.lazy(() => import('./components/superAdmin/DeleteThirdParty'));
const AddThirdParty          = React.lazy(() => import("./components/superAdmin/AddThirdParty"));
const EditThirdParty         = React.lazy(() => import("./components/superAdmin/EditThirdParty"));
const UserTablePage          = React.lazy(() => import("./components/admin/UserTablePage"));
const EditUser               = React.lazy(() => import("./components/admin/EditUser"));
const AddUser                = React.lazy(() => import("./components/admin/AddUser"));
const DeleteUser             = React.lazy(() => import("./components/admin/DeleteUser"));
const Login                  = React.lazy(() => import("./components/Login"));
const ResetPassword          = React.lazy(() => import("./components/ResetPassword"));
const SUMRVerifyPage         = React.lazy(() => import("./components/user/SUMRVerifyPage"));
const SUMRSubmitPage         = React.lazy(() => import("./components/user/SUMRSubmitPage"));
const AuditLogTablePage      = React.lazy(() => import("./components/AuditLogTablePage"));
const NoPageFound            = React.lazy(() => import("./components/NoPageFound"));
const AcknowledgementPage    = React.lazy(() => import("./components/AcknowledgementPage"));

const MainContentWrapper = styled.div<{isLoggedIn : boolean}>`
    grid-area: wrapper;
    display: grid;
    /* If user is logged in we want to show a side nav */
    grid-template-areas:
      ${props => props.isLoggedIn ? `"nav main main main"` : `"main"` };
    margin: 40px 8rem;
    grid-template-columns: repeat(${props => props.isLoggedIn ? 4 : 1 }, 1fr);
`

const LogoContainer = styled(FlexRowContainer)`
    margin-top: .55rem;
    gap: 0 !important;
`

const LogoImage = styled.img`
    
`

function App() {
    const auth = useAuth();
    const location = useLocation();
    const messageService = useMessageService();
    const path = usePath();

    const locationRef = useRef(location);

    // If the location changes:
    // 1. Clear all messages
    // 2. If there are success of warning messages to show upon redirect, show them
    React.useEffect(() => {
        // Set title for pages
        document.title = `${process.env.REACT_APP_TITLE_NAME} - ${path.getTitleFromPathName(location.pathname)}`;

        // verify the path actually changed
        if (location.pathname !== locationRef.current.pathname) {
            messageService.clearAll();

            const {successMessage, warningMessage} = location.state || {};

            if (successMessage) { messageService.success(successMessage); }
            if (warningMessage) { messageService.warning(warningMessage); }

            locationRef.current = location;
        }
    }, [location]);

    // log the user out if they're idle
    const onIdle = () => {
        if (auth.user) {
            auth.logout("Your session has timed out.");
        }
    }

    useIdleTimer({
        onIdle,
        timeout: process.env.REACT_APP_IDLE_TIMEOUT_MINUTES ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES)*60000 : 90000
    })

    return (
        <div className="App">
            <header>
                <LogoContainer>
                    <img src={firstEnergyLogo} style={{ height: 30 }} alt="logo" />&nbsp;Third Party
                </LogoContainer>
                {
                    auth.user &&
                    <div>Welcome {auth.user.firstName}</div>
                }
            </header>
            {/*This will display any messages added to the app via the messageService*/}
            <MessageContainer />
            {
                auth.user
                ?
                    <MainContentWrapper isLoggedIn={true}>
                        <SideNav />
                        <main>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    {/*Super admin only pages*/}
                                    {
                                        auth.user.superAdmin &&
                                        <React.Fragment>
                                            <Route path={path.get(Path.ThirdPartyTable)}       element={<ThirdPartyTablePage />   } />
                                            <Route path={path.get(Path.DeleteThirdParty)}      element={<DeleteThirdParty />      } />
                                            <Route path={path.get(Path.AddThirdParty)}         element={<AddThirdParty />         } />
                                            <Route path={path.get(Path.EditThirdParty)}        element={<EditThirdParty />        } />
                                        </React.Fragment>
                                    }
                                    {/*Super admin and admin only pages*/}
                                    {
                                        (auth.user.superAdmin || auth.user.admin) &&
                                        <React.Fragment>
                                            <Route path={path.get(Path.UserTable)}  element={<UserTablePage /> } />
                                            <Route path={path.get(Path.EditUser)}   element={<EditUser />      } />
                                            <Route path={path.get(Path.AddUser)}    element={<AddUser />       } />
                                            <Route path={path.get(Path.DeleteUser)} element={<DeleteUser />    } />
                                        </React.Fragment>
                                    }
                                    {/*Admin only pages*/}
                                    {
                                        (!auth.user.superAdmin && auth.user.admin) &&
                                        <React.Fragment>
                                            <Route path={path.get(Path.AttestPage)} element={<ManualAttestPage />  } />
                                        </React.Fragment>
                                    }
                                    {/*Pages available to any user*/}
                                    <Route path={path.get(Path.Login)}         element={<Login />             } />
                                    <Route path={path.get(Path.ResetPassword)} element={<ResetPassword />     } />
                                    <Route path={path.get(Path.SUMR)}          element={<SUMRVerifyPage />    } />
                                    <Route path={path.get(Path.SUMRSubmit)}    element={<SUMRSubmitPage />    } />
                                    <Route path={path.get(Path.AuditLog)}      element={<AuditLogTablePage /> } />
                                    <Route path={'*'}                          element={<NoPageFound />       } />
                                </Routes>
                            </Suspense>
                        </main>
                    </MainContentWrapper>
                :
                    <MainContentWrapper isLoggedIn={false}>
                        <main>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    <Route index path={path.get(Path.Login)}         element={<Login />               } />
                                    <Route path={path.get(Path.ResetPassword)}       element={<ResetPassword />       } />
                                    <Route path={path.get(Path.AttestPage)}          element={<PostLoginAttestPage /> } />
                                    <Route path={path.get(Path.AcknowledgementPage)} element={<AcknowledgementPage /> } />
                                    {
                                        /*
                                            If user is not logged in, and they try to navigate to any url besides login,
                                            then this will redirect them to the login page
                                        */
                                    }
                                    <Route path={'*'} element={<Navigate to="/login" />} />
                                </Routes>
                            </Suspense>
                        </main>
                    </MainContentWrapper>
            }
        </div>
    );
}

export default App;
